import LogRocket from 'logrocket';

import { logError } from 'utils/extra';

export default function tagSessionVideo(text) {
  try {
    if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
      console.log('Tag Video: ', text);
    }
    const logRocketApp = process.env.REACT_APP_LOG_ROCKET_APP_ID;
    if (!logRocketApp) {
      return;
    }
    LogRocket.track(text);
  } catch (e) {
    logError(e);
  }
}

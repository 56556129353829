import isPepper from './isPepper';
import isSwetTailor from './isSwetTailor';
import isHoneylove from './isHoneylove';
import attachCustomScripts from './attachCustomScripts';
import isPickupSchedulingAllowed from './isPickupSchedulingAllowed';
import isYummie from './isYummie';
import isDuckcamp from './isDuckcamp';
import isRowingBlazers from './isRowingBlazers';
import isAlvies from './isAlvies';
import isGeologie from './isGeologie';
import isJemma from './isJemma';
import isFuseLenses from './isFuseLenses';
import isAndyAndEvan from './isAndyAndEvan';
import isHellolesley from './isHellolesley';
import isRampley from './isRampley';
import isForeverYoung from './isForeverYoung';
import isOuiBabe from './isOuiBabe';
import isTheLittleBraCompany from './isTheLittleBraCompany';

export {
  isPepper,
  isSwetTailor,
  isHoneylove,
  attachCustomScripts,
  isPickupSchedulingAllowed,
  isYummie,
  isDuckcamp,
  isRowingBlazers,
  isAlvies,
  isGeologie,
  isJemma,
  isFuseLenses,
  isAndyAndEvan,
  isHellolesley,
  isRampley,
  isForeverYoung,
  isOuiBabe,
  isTheLittleBraCompany
};

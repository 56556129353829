import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

const logMessage = message => {
  if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    console.log('Log Message: ', message);
  }
  LogRocket.captureMessage(message);
  Sentry.captureMessage(message);
};
export default logMessage;

import _ from 'lodash';

import { isValidTransport } from './validators';

export default function createEventData(
  category = '',
  action = '',
  extraOptions = {}
) {
  const eventData = { category, action };
  const label = _.get(extraOptions, 'label', null);
  const value = _.get(extraOptions, 'value', null);
  const nonInteraction = _.get(extraOptions, 'nonInteraction', null);
  const transport = _.get(extraOptions, 'transport', null);

  if (_.isString(label)) eventData.label = label;
  if (_.isInteger(value)) eventData.value = value;
  if (_.isBoolean(nonInteraction)) eventData.nonInteraction = nonInteraction;
  if (isValidTransport(transport)) eventData.transport = transport;

  return eventData;
}

import React, { createContext } from 'react';

import { useGoogleAnalytics } from '../hooks';

const GoogleAnalyticsContext = createContext(null);

function GoogleAnalyticsProvider({ children = null }) {
  const gaData = useGoogleAnalytics();

  return (
    <GoogleAnalyticsContext.Provider value={gaData}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
}

export { GoogleAnalyticsProvider, GoogleAnalyticsContext };

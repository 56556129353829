import _ from 'lodash';

const isNonEmptyString = value => _.isString(value) && !_.isEmpty(value);

const isValidEventData = (category, action) => {
  const isValidCategory = isNonEmptyString(category);
  const isValidAction = isNonEmptyString(action);
  return isValidCategory && isValidAction;
};

const isValidTransport = value => {
  const valueMap = { beacon: true, xhr: true, image: true };
  return _.get(valueMap, value, false);
};

export { isNonEmptyString, isValidEventData, isValidTransport };

import React from 'react';
import get from 'lodash/get';
import { Redirect, Route } from 'react-router-dom';

import { If } from 'utils';
import { getUrlParameter } from 'utils/methods';
import routes from 'dictionary/routes';
import { AuthConsumer } from 'context/AuthContext';
import { DynamicTextColor, Footer, PoweredByBar } from 'components/Common';

// routes config

/**
 * ProtectedRoute
 *
 * 1. If the user is not logged in, location.pathname is passed as a query paramter
 *    to the login URL so that the user can get back to the original URL
 *    after logging in (Sarthak)
 */
const ProtectedRoute = ({
  component: Component,
  hasFooter,
  hasAspenBranding,
  ...rest
}) => (
  <AuthConsumer>
    {({ appError, isLoggedIn }) => (
      <Route
        render={props =>
          isLoggedIn ? (
            <>
              <div className="mainWrapper__container">
                <Component {...props} generateError={appError} />
                {hasFooter ? <Footer /> : null}
              </div>
              <If test={hasAspenBranding}>
                <DynamicTextColor
                  containerClassname="powered-by-footer-container"
                  opacity={0.25}
                  content={<PoweredByBar />}
                />
              </If>
            </>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                search: `?redirectTo=${window.location.pathname}`
              }}
            />
          )
        }
        {...rest}
      />
    )}
  </AuthConsumer>
);

const GiftRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ appError, isLoggedIn, login, userData, getLoginOrderID }) => (
      <Route
        render={props => {
          const redirectTo = getUrlParameter(window.location, 'redirectTo');
          const loginOrderID = getLoginOrderID();
          //
          // When a user is on /giftReturn and logs in, isLoggedIn is set to true
          // If there is a redirectTo URL parameter, that should get preference
          // Else if there is a orderID set by login() in AuthContext, it should redirect
          // to orderService init
          // Else render as usual (Sarthak)
          if (isLoggedIn && redirectTo) {
            return <Redirect to={`${redirectTo}`} />;
          } else if (loginOrderID) {
            return <Redirect to={`/orderService/${loginOrderID}`} />;
          }
          return (
            <Component
              {...props}
              generateError={appError}
              login={login}
              isLoggedIn={isLoggedIn}
              userData={userData}
            />
          );
        }}
        {...rest}
      />
    )}
  </AuthConsumer>
);

/**
 * UnProtectedRoute
 *
 * 1. Once the user logs in and if they have a redirectTo URL in their search params,
 *    then redirect the url to that URL. Else redirect to home (Sarthak)
 */
const UnProtectedRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isLoggedIn, login, getLoginOrderID }) => (
      <Route
        render={props => {
          const redirectTo = getUrlParameter(window.location, 'redirectTo');
          const loginOrderID = getLoginOrderID();
          // If the user is not logged in, then the unprotected route is shown
          // When the user logs in, isLoggedIn is set to true
          // If there is redirect URL paramater that should get preference in re-direction
          // Otherwise it is redirected to home route with the orderID passed as state object
          // (Sarthak)
          if (!isLoggedIn) {
            return <Component {...props} login={login} />;
          } else if (redirectTo) {
            return <Redirect to={`${redirectTo}`} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { orderID: loginOrderID }
                }}
              />
            );
          }
        }}
        {...rest}
      />
    )}
  </AuthConsumer>
);

const ConditionalRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isLoggedIn }) => (
      <Route
        render={props => {
          // If the user is not logged in, take them to the login page. (ACX-393)
          // Else If the orderID has been passed as stateObject by other component, redirect to Order Service Init
          // Else redirect to order list page (Sarthak)
          if (!isLoggedIn) {
            return <Redirect from="/" to="/login" />;
          } else if (!get(props, 'location.state.orderID')) {
            return <Redirect from="/" to={routes.orders} />;
          }
          return (
            <Redirect
              from="/"
              to={`/orderService/${props.location.state.orderID}`}
            />
          );
        }}
        {...rest}
      />
    )}
  </AuthConsumer>
);
const PublicRoute = ({
  component: Component,
  hasAspenBranding,
  covidDisclaimer,
  ...rest
}) => (
  <AuthConsumer>
    {({ appError }) => (
      <Route
        render={props => {
          return (
            <React.Fragment>
              <Component {...props} generateError={appError} />
              <If test={hasAspenBranding}>
                <DynamicTextColor
                  opacity={0.25}
                  containerClassname="powered-by-footer-container"
                  content={<PoweredByBar covidDisclaimer={covidDisclaimer} />}
                />
              </If>
            </React.Fragment>
          );
        }}
        {...rest}
      />
    )}
  </AuthConsumer>
);
export {
  ProtectedRoute,
  UnProtectedRoute,
  ConditionalRoute,
  GiftRoute,
  PublicRoute
};

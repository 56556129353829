import LogRocket from 'logrocket';

export default function logRocketInit() {
  const logRocketApp = process.env.REACT_APP_LOG_ROCKET_APP_ID;

  if (logRocketApp) {
    LogRocket.init(logRocketApp, {
      console: {
        shouldAggregateConsoleErrors: true
      },
      release: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_CURRENT_GIT_SHA}`
    });
  }
}

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { validate, createEventData } from '../methods';

export default function useGoogleAnalytics() {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.pageview(`${window.location.origin}${pathname}`);
  }, [pathname]);

  const recordEvent = (
    category = '',
    action = '',
    extraOptions = {
      label: null, // String
      value: null, // Integer
      nonInteraction: null, // Boolean
      transport: null // String (beacon|xhr|image)
    }
  ) => {
    const isValid = validate.isValidEventData(category, action);

    if (!isValid) {
      throw new Error(
        'Google Analytics: Event recording required arguments are invalid'
      );
    }

    const eventData = createEventData(category, action, extraOptions);
    ReactGA.event(eventData);
  };

  return { recordEvent };
}

import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Loader } from 'components';
import {
  user,
  localStore
  // isOnNewFlow
} from 'utils';
import { logError } from 'utils/extra';
import { identifyUser } from 'utils/analytics';
import { attachCustomScripts } from 'utils/retailer';
import loadDictionaryPromise from 'config/setup/loadDictionary';
import { formatStoreProperties, loadJsCssFile } from 'utils/methods';
import logRocketInit from '../config/setup/logRocketInit';
import { RETURN_AND_EXCHANGE, ONLY_RETURN } from '../dictionary';
import fetchStoreProperties from '../config/setup/fetchStoreProperties';

const DEMO_APP_SUBDOMAIN = 'instant-demo';

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = {
    isReady: false,
    isLoggedIn: user.isLoggedIn(),
    hasError: false,
    errorObject: null,
    userData: user.get(),
    isLoadingStoreProperties: true,
    serviceCopies: {},
    aspenBenefits: [],
    allowPhoneLogin: false,
    inputLoginCombo: [],
    allowsExchange: true,
    allowsDiscountForwarding: true,
    canCreateRequest: true,
    isDemoApp: false,
    storeProperties: {}
  };
  storeDetails = {};
  loginOrderID = null;
  appType = {
    retex: process.env.REACT_APP_TYPE !== 'SHIPMENT_TRACKING',
    shipmentTracking: process.env.REACT_APP_TYPE === 'SHIPMENT_TRACKING'
  };
  uid = uuidv4();

  componentDidMount() {
    this.initLogRocket();
    this.bindEvents();
    this.getStoreDetails();
    if (this.appType.retex) {
      this.fetchMasterData();
      this.attachTheme();
      this.attachScripts();
      window.SolvvySettings = { position: 'bottom-left' };
    } else {
      this.setState({
        isReady: true,
        isLoadingStoreProperties: false
      });
    }

    this.checkIfDemoApp();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.hasError && prevState.hasError === this.state.hasError) {
    //   this.setState({
    //     hasError: false,
    //     errorObject: null
    //   });
    // } else
    if (this.state.hasError && prevProps.location !== this.props.location) {
      // On changing route, cleanup
      this.setState({
        hasError: false,
        errorObject: null
      });
    }
  }

  bindEvents = () => {
    // When unknown errors are thrown, the UI needs to fallback to the error state (Sarthak)
    window.onerror = (message, source, lineno, colno, error) => {
      this.error(error);
    };
  };

  checkIfDemoApp = () => {
    const host = window.location.host;

    const [subDomain = ''] = host.split('.');
    const isDemoApp = subDomain === DEMO_APP_SUBDOMAIN;

    this.setState({
      isDemoApp
    });
  };

  // Function to fetch store detail based on url based parameter
  getStoreDetails = () => {
    fetchStoreProperties(
      response => {
        const data = JSON.parse(response);
        const storeDetailsObject = formatStoreProperties(data);
        const allowsExchange = _.get(data, 'is_exchange_allowed', true);
        const canCreateRequest = _.get(data, 'can_create_request', false);
        const allowPhoneLogin = _.get(data, 'allow_phone_number_login', false);
        const allowsDiscountForwarding = _.get(
          data,
          'is_discount_forwarding_enabled',
          false
        );
        const { serviceCopies, aspenBenefits } = allowsExchange
          ? RETURN_AND_EXCHANGE
          : ONLY_RETURN;
        this.storeDetails = storeDetailsObject;
        this.setState({
          aspenBenefits,
          serviceCopies,
          allowsExchange,
          allowPhoneLogin,
          canCreateRequest,
          storeProperties: data,
          allowsDiscountForwarding,
          isLoadingStoreProperties: false
        });
      },
      err => {
        this.storeDetails = {};
        this.error(err);
        this.setState({ isLoadingStoreProperties: false });
      }
    );
  };

  attachTheme = () => {
    const [retailer] = window.location.host.split(':');
    loadJsCssFile(
      `https://res.cloudinary.com/supply-ai/raw/upload/store-theme/${retailer}.css?v=${this.uid}`,
      'css'
    );
  };

  initLogRocket = () => {
    try {
      const {
        location: { pathname }
      } = this.props;
      const isRouteShipmentTracking = _.startsWith(pathname, '/tracking');
      if (!isRouteShipmentTracking) {
        logRocketInit();
      }
    } catch (e) {
      logError(e);
    }
  };

  attachScripts = () => attachCustomScripts();

  fetchMasterData = () => {
    loadDictionaryPromise(() => this.setState({ isReady: true }));
  };

  getLoginOrderID = () => {
    // Clears loginOrderID after it is accessed one time
    const loginOrderID = this.loginOrderID;
    if (loginOrderID) {
      this.loginOrderID = null;
      return loginOrderID;
    }
    return null;
  };

  login = data => {
    try {
      localStore.put('authToken', data.accessToken);
      localStore.put('retailer', data.retailer_name);
      localStore.put('userData', JSON.stringify(data.userData));

      identifyUser();

      const orderName = _.get(data, 'retailerOrderId', '');
      this.loginOrderID = orderName ? encodeURIComponent(orderName) : null;
      this.setState({ isLoggedIn: true, userData: data.userData });
    } catch (e) {
      logError(e);
    }
  };

  logout = (callback = () => {}) => {
    user.logout();
    sessionStorage.clear();
    this.loginOrderID = null;
    this.setState({ isLoggedIn: false, userData: null }, callback);
  };

  error = errorObject => {
    if (this.state.hasError) return;
    if (errorObject) {
      logError(errorObject);
    }
    this.setState({
      hasError: true,
      errorObject
    });
  };

  setInputLogin = combo => this.setState({ inputLoginCombo: combo });

  render() {
    const {
      isReady,
      storeProperties,
      isLoadingStoreProperties,
      serviceCopies,
      hasError,
      isLoggedIn,
      errorObject,
      userData,
      aspenBenefits,
      allowPhoneLogin,
      inputLoginCombo,
      allowsExchange,
      canCreateRequest,
      allowsDiscountForwarding,
      isDemoApp
    } = this.state;

    if (!isReady || isLoadingStoreProperties) {
      return <Loader customClass="app-loader" />;
    }

    return (
      <AuthContext.Provider
        value={{
          serviceCopies,
          hasError,
          isLoggedIn,
          errorObject,
          aspenBenefits,
          storeProperties,
          canCreateRequest,
          userData: isLoggedIn ? userData : null,
          allowPhoneLogin: allowPhoneLogin,
          inputLoginCombo: inputLoginCombo,
          setInputLogin: this.setInputLogin,
          login: this.login,
          logout: this.logout,
          appError: this.error,
          getLoginOrderID: this.getLoginOrderID,
          getStoreDetails: () => this.storeDetails,
          appType: this.appType,
          allowsExchange,
          allowsDiscountForwarding,
          isDemoApp
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

const ConnectedAuthProvider = withRouter(AuthProvider);

export { ConnectedAuthProvider as AuthProvider, AuthConsumer, AuthContext };
